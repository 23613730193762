<template>
  <b-overlay
    :show="stylistStore.isPending || openHoursStore.isPending || servicesStore.isPending"
    variant="secondary"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="lg"
    class="min-vh-100"
  >
    <div
      v-if="Object.keys(stylistData).length"
      id="user-profile"
    >
      <stylist-page-header :header-data="stylistData.header" />
      <!-- stylist info  -->
      <section
        id="stylist-info"
        class="pb-2 py-md-4 px-sm-2 px-lg-4"
      >
        <b-container>
          <b-row>
            <!-- left column / description, services -->
            <b-col
              xl="8"
            >
              <stylist-page-services :services="stylistData.services" />
            </b-col>
            <!-- left column / description, services -->

            <!-- right column / contact, open hours, social media -->
            <b-col
              xl="4"
            >
              <stylist-page-rating :reviews-details="reviewsStore.response" />
              <div class="pl-xl-4">
                <stylist-page-open-hours :open-hours-data="stylistData.openHours" />
              </div>
            </b-col>
          <!--/ right column / contact, open hours, social media -->
          </b-row>
          <tbb-card
            class="text-center mt-5 pt-2 centered"
            title="Contact"
          >
            <b-card class="mt-2 custom-card">
              <stylist-page-contact :contact-data="stylistData.contact" />
              <stylist-page-social-media :social-media-data="stylistData.socialMedia" />
            </b-card>
          </tbb-card>
          <!-- TODO Add possibility to add description in Admin Dashboard -->
          <stylist-page-description
            v-if="stylistData.description.businessDescription"
            :description="stylistData.description"
          />
          <stylist-page-reviews
            :reviews-details="reviewsStore.response"
          />
        </b-container>
      </section>
    <!--/ stylist info  -->
    </div>
  </b-overlay>
</template>

<script>
import {
  BRow,
  BCol,
  BOverlay,
  BContainer,
  BCard,
} from 'bootstrap-vue'

import { mapActions, mapState } from 'vuex'
import { COMMON_ERROR_404 } from '@/router/routes/routes-path'
import { AlertTriangleIcon } from 'vue-feather-icons'
import { TYPE } from 'vue-toastification'
import StylistPageDescription from './StylistPageDescription.vue'
import StylistPageHeader from './StylistPageHeader.vue'
import StylistPageServices from './StylistPageServices.vue'
import StylistPageContact from './StylistPageContact.vue'
import StylistPageOpenHours from './StylistPageOpenHours.vue'
import StylistPageSocialMedia from './StylistPageSocialMedia.vue'
import StylistPageReviews from './StylistPageReviews.vue'
import StylistPageRating from './StylistPageRating.vue'
import TbbCard from '../../sites/TbbCard.vue'

/* eslint-disable global-require */
export default {
  components: {
    StylistPageDescription,
    BRow,
    BCol,
    BOverlay,
    BContainer,
    BCard,
    TbbCard,

    StylistPageHeader,
    StylistPageServices,
    StylistPageContact,
    StylistPageOpenHours,
    StylistPageSocialMedia,
    StylistPageReviews,
    StylistPageRating,
  },
  data() {
    return {
      stylistData: {
        header: {
          avatar: require('@/assets/images/user-uploads/stylist-profile/avatars/avatar-s-2.jpg'),
          businessName: null,
          coverImg: require('@/assets/images/user-uploads/stylist-profile/backgrounds/timeline.jpg'),
        },
        description: {
          businessDescription: null,
        },
        contact: {
          email: null,
          businessName: null,
          address: null,
          city: null,
          postCode: null,
        },
        openHours: null,
        socialMedia: {
          facebookUrl: null,
          instagramUrl: null,
          twitterUrl: null,
        },
        services: null,
      },
    }
  },
  computed: {
    ...mapState('StylistPageStoreModule', {
      stylistStore: state => state.stylist,
      openHoursStore: state => state.openHours,
      servicesStore: state => state.services,
      reviewsStore: state => state.reviews,
    }),
  },
  watch: {
    stylistStore: {
      deep: true,
      handler(stylist) {
        if (stylist.error && stylist.error.response.status === 404) {
          window.location.href = `https://${this.$store.getters['app/getDomain']}${COMMON_ERROR_404}`
        }
        if (stylist.response) {
          // Header
          this.stylistData.header.businessName = stylist.response.businessName
          // Description
          this.stylistData.description.businessDescription = stylist.response.businessDescription
          // Contact
          this.stylistData.contact.businessName = stylist.response.businessName
          this.stylistData.contact.email = stylist.response.email
          this.stylistData.contact.address = stylist.response.address
          this.stylistData.contact.city = stylist.response.city
          this.stylistData.contact.postCode = stylist.response.postCode
          // Social media
          this.stylistData.socialMedia.facebookUrl = stylist.response.facebookUrl
          this.stylistData.socialMedia.twitterUrl = stylist.response.twitterUrl
          this.stylistData.socialMedia.instagramUrl = stylist.response.instagramUrl
        }
        if (stylist.error) {
          this.$toast('Error fetching stylist data!', {
            icon: AlertTriangleIcon,
            type: TYPE.ERROR,
          })
        }
      },
    },
    openHoursStore: {
      deep: true,
      handler(newOpenHours) {
        if (newOpenHours.response) {
          this.stylistData.openHours = newOpenHours.response
        }
        if (newOpenHours.error) {
          this.$toast('Error fetching open hours!', {
            icon: AlertTriangleIcon,
            type: TYPE.ERROR,
          })
        }
      },
    },
    servicesStore: {
      deep: true,
      handler(newServices) {
        if (newServices.response) {
          this.stylistData.services = newServices.response
        }
        if (newServices.error) {
          this.$toast('Error fetching services!', {
            icon: AlertTriangleIcon,
            type: TYPE.ERROR,
          })
        }
      },
    },
  },
  mounted() {
    this.fetchPublicStylist(this.$store.getters['app/getSubdomain'])
    this.fetchPublicOpenHours()
    this.fetchPublicServices()
    this.fetchPublicReviews()
  },
  methods: {
    ...mapActions('StylistPageStoreModule', [
      'fetchPublicStylist',
      'fetchPublicOpenHours',
      'fetchPublicServices',
      'fetchPublicReviews',
    ]),
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-profile.scss';
</style>
<style lang="scss" scoped>
  .custom-card{
    width: 100%;
    max-width: 350px;
  }
</style>
