<template>
  <tbb-card
    class="pl-xl-4"
    title="Rating"
  >
    <b-row
      align-v="baseline"
      class="justify-content-center"
    >
      <b-col cols="auto">
        <span class="d-block d-md-inline-block">
          <span class="d-block d-md-inline-block hearts-margin">
            <b-form-rating
              id="rating"
              v-model="average"
              icon-empty="heart"
              icon-half="heart-half"
              icon-full="heart-fill"
              inline
              readonly
              precision="2"
              no-border
              variant="primary"
              class="no-background font-large-1"
            />
          </span>
          <span class="d-block text-center mb-1">
            <span class="d-block d-md-inline-block"><span
              class="label"
            >Average:</span> {{ average }}</span>
            <span class="d-none d-md-inline-block separator">//</span>
            <span class="d-block d-md-inline-block"><span class="label">Total:</span> {{ totalItems }}</span>
          </span>
        </span>

      </b-col>
    </b-row>
    <!-- Open hours -->
  </tbb-card>
</template>

<script>
import {
  BRow, BCol, BFormRating, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { kFormatter } from '@core/utils/filter'
import TbbCard from '../../sites/TbbCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormRating,
    TbbCard,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    reviewsDetails: {
      type: Object,
      default: () => [],
    },
  },
  data() {
    return {
      maxRating: 5,
      totalItems: 0,
      average: 0,
    }
  },
  watch: {
    reviewsDetails: {
      deep: true,
      handler(newReviewsDetailsData) {
        if (newReviewsDetailsData?.total_items) {
          this.totalItems = newReviewsDetailsData.total_items
          this.average = newReviewsDetailsData.average
        }
      },
    },
  },
  methods: {
    kFormatter,
  },
}
</script>
<style lang="scss" scoped>
.list-group-item {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 0;
  @media (hover: hover) {
    .description {
      transition: transform 0.3s;
    }
    transition: background-color 0.3s;
    &:hover {
      background-color: #fff;

      .description {
        transform: translateX(1.25rem);
      }
    }
  }
  @media (max-width: 767.98px) {
    padding-right: 0;
  }
}

p {
  margin-top: 0.5rem;
  font-weight: 500;
}

.label {
  font-weight: 400;
  text-transform: uppercase;
  color: #686868;
  font-size: 0.8em;
}

.separator {
  color: #d1b08f;
  margin: 0 0.5rem;
}

.red {
  color: red;
}

.heart-margin {
  margin: 0.1rem;
}

.hearts-margin {
  margin: 1rem 1rem 0.5rem 1rem !important;
}

.no-background {
  background: transparent !important;
}
</style>
