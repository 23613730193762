<template>
  <div>
    <b-card>
      <b-card-text>
        <h5 class="text-capitalize mb-75">
          Description
        </h5>
        {{ description.businessDescription }}
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardText, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { kFormatter } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BCardText,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    description: {
      type: Object,
      default: () => [],
    },
  },
  methods: {
    kFormatter,
  },
}
</script>
