<template>
  <div>
    <b-card>
      <b-card-text>
        <h5 class="text-capitalize mb-75">
          Reviews
        </h5>
        <span class="d-block d-md-inline-block"><span class="label">Total number of reviews:</span> {{ totalItems }}</span>
        <template>
          <b-list-group
            flush
          >
            <b-list-group-item
              v-for="(review, index) in reviews"
              :key="index"
              ref="row"
            >
              <b-row align-v="center">
                <b-col class="description">
                  <h4 class="mb-0">
                    {{ review.appointment.services | servicesString }}
                  </h4>
                  <p class="mb-0">
                    <span class="d-block d-md-inline-block"><span
                      class="label"
                    >Date:</span> {{ review.createdAt | dateUsLocale }}</span>
                    <span class="d-none d-md-inline-block separator">//</span>
                    <span class="d-block d-md-inline-block"><span class="label">Name:</span> {{ review.customer.name }}</span>
                    <span class="d-none d-md-inline-block separator">//</span>
                    <span class="d-block d-md-inline-block"><span class="label">Rating:</span>
                      <span class="d-block d-md-inline-block hearts-margin-left">
                        <b-form-rating
                          id="rating"
                          v-model="review.rate"
                          icon-empty="heart"
                          icon-half="heart-half"
                          icon-full="heart-fill"
                          inline
                          readonly
                          precision="2"
                          no-border
                          variant="primary"
                          class="no-background font-small-4 p-0"
                        />
                      </span>
                    </span>
                    <span class="d-block label">{{ review.message }}</span>
                  </p>
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item
              v-if="!reviews"
            >
              <b-row align-v="center">
                <b-col class="description">
                  <p class="mb-0">
                    <span class="d-block d-md-inline-block">No reviews!</span>
                  </p>
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-list-group>
        </template>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import {
  BListGroup, BListGroupItem, BRow, BCol, BCard, BCardText, BFormRating, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { kFormatter } from '@core/utils/filter'
import moment from 'moment'

export default {
  filters: {
    servicesString(services) {
      const servicesArray = services.map(service => service.name)
      return servicesArray.join(', ')
    },
    dateUsLocale(date) {
      return moment(date, 'YYYY-MM-DD HH:mm').format('MMMM D, yyyy')
    },
  },
  components: {
    BCard,
    BRow,
    BCol,
    BCardText,
    BListGroup,
    BListGroupItem,
    BFormRating,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    reviewsDetails: {
      type: Object,
      default: () => [],
    },
  },
  data() {
    return {
      maxRating: 5,
      totalItems: 0,
      reviews: 0,
    }
  },
  watch: {
    reviewsDetails: {
      deep: true,
      handler(newReviewsDetailsData) {
        if (newReviewsDetailsData?.total_items) {
          this.totalItems = newReviewsDetailsData.total_items
          this.reviews = newReviewsDetailsData.reviews
        }
      },
    },
  },
  methods: {
    kFormatter,
  },
}
</script>
<style lang="scss" scoped>
.list-group-item {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 0;
  @media (hover: hover) {
    .description {
      transition: transform 0.3s;
    }
    transition: background-color 0.3s;
    &:hover {
      background-color: #fff;

      .description {
        transform: translateX(1.25rem);
      }
    }
  }
  @media (max-width: 767.98px) {
    padding-right: 0;
  }
}

p {
  margin-top: 0.5rem;
  font-weight: 500;
}

.label {
  font-weight: 400;
  text-transform: uppercase;
  color: #686868;
  font-size: 0.8em;
}

.separator {
  color: #d1b08f;
  margin: 0 0.5rem;
}

.red {
  color: red;
}

.heart-margin {
  margin: 0.1rem;
}

.hearts-margin-left {
  margin-left: 0.3rem;
}

.no-background {
  background: transparent !important;
}
</style>
