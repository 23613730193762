<template>
  <tbb-card
    class="mb-4 mb-xl-0"
    title="Services"
  >
    <template v-slot:extraHeader>
      <b-button
        v-if="bookingServices.length"
        variant="outline-primary"
        class="align-self-center"
        @click="$router.push({name: bookingPage })"
      >
        Back to booking
      </b-button>
    </template>
    <template>
      <b-list-group
        flush
      >
        <b-list-group-item
          v-for="(service, index) in services"
          :key="index"
          ref="row"
        >
          <b-row align-v="center">
            <b-col class="description">
              <h3 class="mb-0">
                {{ service.name }}
              </h3>
              <p class="mb-0">
                <span class="d-block d-md-inline-block"><span class="label">Price:</span> ${{ service.price }}</span>
                <span class="d-none d-md-inline-block separator">//</span>
                <span class="d-block d-md-inline-block"><span class="label">Duration:</span> {{ service.duration }} min</span>
              </p>
            </b-col>
            <b-col
              cols="auto"
            >
              <b-button
                v-if="service.hourly"
                variant="primary"
                @click="requestConsultationService(service)"
              >Request Consultation</b-button>
              <b-button
                v-if="!service.hourly"
                variant="primary"
                @click="bookService(service)"
              >Book</b-button>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
    </template>
  </tbb-card>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { kFormatter } from '@core/utils/filter'
import {
  BListGroup, BListGroupItem, BCol, BRow, BButton,
} from 'bootstrap-vue'
import {
  BOOKING_FIRST_STEP_PAGE,
  SERVICE_REQUEST_CONSULTATION,
} from '@/router/routes/routes-names'
import CryptoJS from 'crypto-js'
import TbbCard from '../../sites/TbbCard.vue'

export default {
  components: {
    BListGroup,
    BListGroupItem,
    BCol,
    BRow,
    BButton,
    TbbCard,
  },
  directives: {
    Ripple,
  },
  props: {
    services: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      bookingServices: [],
      bookingPage: BOOKING_FIRST_STEP_PAGE,
    }
  },
  created() {
    if (this.$cookies.get('booking_services')) {
      this.bookingServices = JSON.parse(CryptoJS.AES.decrypt(this.$cookies.get('booking_services'), process.env.VUE_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8))
    }
  },
  methods: {
    kFormatter,
    bookService(service) {
      this.bookingServices.push(service)
      const servicesCrypted = CryptoJS.AES.encrypt(
        JSON.stringify(this.bookingServices),
        process.env.VUE_APP_SECRET_KEY,
      ).toString()
      this.$cookies.set('booking_services', servicesCrypted, '15min')
      this.$router.push({ name: BOOKING_FIRST_STEP_PAGE })
    },
    requestConsultationService(service) {
      this.$router.push({ name: SERVICE_REQUEST_CONSULTATION, params: { id: service.id } })
    },
  },
}
</script>
<style lang="scss" scoped>
.list-group-item{
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 0;
  @media (hover:hover){
    .description{
      transition: transform 0.3s;
    }
    transition: background-color 0.3s;
    &:hover{
      background-color: #fff;
      .description{
        transform: translateX(1.25rem);
      }
    }
  }
  @media (max-width:767.98px){
    padding-right: 0;
  }
}

h3{
  font-size: 2rem;
  font-weight: 700;
}

p {
  margin-top: 0.5rem;
  font-weight: 500;
}
.label {
  font-weight: 400;
  text-transform: uppercase;
  color: #686868;
  font-size: 0.8em;
}

.separator{
  color: #d1b08f;
  margin: 0 0.5rem;
}
</style>
